import React,{Component} from "react";
import {TuxGlobalHeader} from "@tuxedo/react-component-library";


class Splash extends Component {

  render() {
      return ( <div className="container-fluid containerFormat">
        <div className="row headerRow">
          <TuxGlobalHeader>

            <a className="navbar-brand center" href="#">
              <img className="img-fluid imgDisplay" src="/assets/images/Heart_title_icon_white.svg"
                   alt="JPMC logo"
              />
              <span className="text-white">My Health</span>
            </a>
          </TuxGlobalHeader>
        </div>
        <div >

          <h2 className="maintenance-info">My Health maintenance information</h2>
          <hr/>
          <table width="100%" cellSpacing="30" >
            <tr>
              <td className="custome-cell-1">
                <p><strong>My Health is temporarily unavailable.</strong></p>
                <p>Thanks for your patience as we work on the system.</p>
                <br/><br/>
                <center><img src="/assets/images/OUTAGE_noshadow.png" /></center>
                <br/>
              </td>
              <td>
                <p>
                  <strong>Benefits vendor URLs</strong>
                  <hr/>
                  <a href="https://authe-ent.jpmorgan.com/idp/startSSO.ping?PartnerSpId=saml.alight.com&TargetResource=https://sso.alight.com/portal" target="_blank" rel="noreferrer">Benefits Web Center</a>
                  <i><font size="2"> (Enrollment)</font></i>
                  <br/>
                  <a href="https://authe-ent.jpmorgan.com/idp/startSSO.ping?PartnerSpId=AetLegacyJPMC" target="_blank" rel="noreferrer">Aetna</a>
                  <br/>
                  <a href="https://authe-ent.jpmorgan.com/idp/startSSO.ping?PartnerSpId=https%3A%2F%2Fmy.cigna.com%2Fisam%2Fsps%2Fmycigna-p-sp-jpmc%2Fsaml20" target="_blank" rel="noreferrer">Cigna</a>
                  <br/>
                  <a href="https://authe-ent.jpmorgan.com/idp/startSSO.ping?PartnerSpId=SP_CVS_JPMC2" target="_blank" rel="noreferrer">Caremark</a>
                  <br/>
                  <a href="https://kp.org" target="_blank" rel="noreferrer">Kaiser</a>
                  <i><font size="2"> (for employees living in California)</font></i>
                  <br/>
                  <a href="https://sso.includedhealth.com/inbound/conn/jpmc-jpmc/start" target="_blank" rel="noreferrer">Included Health</a>
                  <i><font size="2"> (FL, GA, LA, OK only)</font></i>
                </p>
              </td>
            </tr>
          </table>
        </div>
      </div>);

  }
}

export default Splash;
