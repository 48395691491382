import React, {Component} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import App from "./App";
import ExternalPage from "./Component/ExternalPages/Home/ExternalPage";
import Error from "./Component/Error";
import PageExtLayout from "./Component/ExternalPages/OtherPages/PageExtLayout";
import Splash from "./Component/Splash";

class RouteMapper extends Component {
  render() {
    return (

      <Switch>

        <Route path="/hp/home" component={ExternalPage}/>
        <Route path="/error_redirect" component={Error}/>
        <Route path="/hrgway" component={App}/>
        <Route path="/splash" component={Splash}/>


        <Route path="/pc" component={App}/>
        <Route path="/" component={PageExtLayout}>
          <BrowserRouter forceRefresh >
            {(window.location.hostname).indexOf("jpmchase.net") > -1 ? (
              <Redirect to="/pc" component={App} />
            ) : (
              ((window.location.pathname)==="/" || (window.location.pathname)==="") ?
                (<Redirect to="/hp/home" component={ExternalPage} />
                ) : (
                  <Route path="/*" component={PageExtLayout}/>
                )
            )}
          </BrowserRouter>
        </Route>


      </Switch>


    );
  }
}
export default RouteMapper;
