import React, { useState ,useEffect} from 'react';
import {useLocation,useHistory} from 'react-router-dom';
import { useSelector } from 'react-redux';
import SiteCatalystComponent from "./SiteCatalystComponent";
import {TuxLoadingIndicator} from "@tuxedo/react-component-library";

function MainComponent()
{
  const [content,setContent] = useState({});
  const [loading,setLoading] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const userData = useSelector(state=>state);
  //const arr = location.pathname.split("/");

  const apptype = "MH";
  const state = userData?.userReducer?.empState ? userData?.userReducer?.empState : "DEFAULT";
  const vendor = userData?.userReducer?.vendor;
  const page = location.pathname;
  let url;
  const getUrl = (page) => {
    console.log("getUrl: page:: " + page);
    if(page!==undefined && page.indexOf("/downloadFile/")!==-1){
      if(page.indexOf("http")!==-1){
        const  splitUrl=page.split("/");
        const host = splitUrl[0]+"//"+splitUrl[2];
        page = page.replace(host,'');
      }
      if(page.indexOf("/pc/api/office365/1.0/downloadFile/")!==-1) {
        page = page.replace("/pc/api/office365/1.0/downloadFile/", "");
        page = page.replace("?appType=MH", "");
        url = "/pc/api/myhealth/1.0/downloadFile/" + page + "?vendor=" + vendor + "&state=" + state + "&appType=" + apptype;
      }else{
        page = page.replace("/api/office365/1.0/downloadFile/", "");
        page = page.replace("?appType=MH", "");
        url = "/api/myhealth/1.0/downloadFile/" + page + "?vendor=" + vendor + "&state=" + state + "&appType=" + apptype;
      }
    }
    else if(page!==undefined && page.indexOf("/pc/api/office365/")!==-1){
      page=page.replace("/pc/api/office365/1.0/pageContent/","");
      page = page.replace("?appType=MH","");
      url = "/pc/api/myhealth/"+page+"?vendor=" + vendor + "&state="+state+"&appType="+apptype;
    }
    else if(page!==undefined && page.indexOf("/api/office365/")!==-1){
      if(page.indexOf("http")!==-1){
        const  splitUrl=page.split("/");
        const host = splitUrl[0]+"//"+splitUrl[2];
        page = page.replace(host,'');
      }
      page=page.replace("/api/office365/1.0/pageContent/","");
      page = page.replace("?appType=MH","");
      url = "/api/myhealth/1.0/pageContent/"+page+"?vendor=" + vendor + "&state="+state+"&appType="+apptype;
    }


    else if(page!==undefined && page.indexOf("/pc/")!==-1){
      page=page.replace("/pc/","");
      url = "/pc/api/myhealth/"+page+"?vendor=" + vendor + "&state="+state+"&appType="+apptype;
    }else if(page!==undefined && page.indexOf("/")!==-1 ){
      page=page.replace("/",""); // to be checked
      url = "/api/myhealth/1.0/pageContent/"+page+"?vendor=" + vendor + "&state="+state+"&appType="+apptype;
    }
    else {
      url=page;
    }
    console.log("getUrl: url:: " + url);
  };

  useEffect(() =>  {
    let data= [];
    data = JSON.parse(sessionStorage.getItem("adobeData"));
    sessionStorage.clear();
    if(data) {
      if(typeof window.aadata==="undefined"){
        window.aadata=[];
      }
      for (let i = window.aadata.length; i < data.length; i++) {
        window.aadata.push(data[i]);
      }
    }
    window.onpopstate=()=>{
      SiteCatalystComponent.tracking("Page Load",window.location.pathname);
    };
    getUrl(page);
    fetchContent(url);
    // window.addEventListener('click', handlePageLink);
    return()=>{
      window.removeEventListener('click',handlePageLink);
    };
  },[page]);

  const handlePageLink=(e)=>{
    let event="Page Load";
    let page = e.target.getAttribute("href");
    if(page == null && e.target != null && e.target.parentElement!=null){
      page = e.target.parentElement.href; //e.target.parentElement.href;
    }
    let result=false;
    if(page) {
      getUrl(page);
      if ((url.indexOf("aspx")!==-1) &&(url.indexOf("myhealth")!==-1)) {
        result = true;
        event = "Page Load";
      } else if ((url.indexOf("downloadFile")!==-1)&&(url.indexOf("office365")!==-1)) {
        event = "Download";
      } else {
        event = "Exit";
      }
      history.push(url);
      SiteCatalystComponent.tracking(event,url);
    }
    if(result) {
      e.preventDefault();
    }
    console.log("event:: " + event);
  };

  const fetchContent=(url)=>{
    fetch(url, {mode: 'no-cors', credentials: 'same-origin'})
      .then((response) => {
        if(response.status === 200){
          response.json().then((resp)=> {
            if(Object.keys(resp).length!==0) {
              setContent(resp.value);
              setLoading(false);
            }
          });
        } else{
          setLoading(false);
          const message = `Response Error: ${response.status}`;
          console.log(message);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log("Error in MainComponent");
        console.log(error);
        window.open("/splash");
      });

  };
  const renderLoading=()=>{
    return (
      <div className="d-flex justify-content-center m-lg-5">
        <div><TuxLoadingIndicator type="pinwheel"/></div>
      </div>
    );
  };
  const renderGet = ()=> {
    if (Object.keys(content).length !== 0) {
      return (
        <div dangerouslySetInnerHTML={{__html: content}}/>
      );
    } else {
      return (<div/>);
    }
  };

  return (<div>

      {loading===true ?
        renderLoading()
        : renderGet()}
    </div>
  );



}
export default MainComponent;
